<script setup lang="ts">
</script>

<template>
	<UContainer class="grid min-h-full grid-rows-[1fr_auto]">
		<WidgetLanguageSwitcher class="fixed top-4 right-4" />
		<slot />
		<TheFooter />
	</UContainer>
</template>

<style>
html {
    @apply bg-slate-100 h-full;
}

#__nuxt {
  @apply h-full;
}

body {
    @apply h-full;
}
</style>
